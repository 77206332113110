import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { ownerCompanies } from "App/Owner/_graphql/queries/company";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import { getCrumbs } from "../_helpers/crumbs";
import InviteCompany from "./_modals/InviteCompany/InviteCompany";
import { masqueradeUser } from "App/Admin/_graphql/mutations/user";
import "./Companies.scss";

function Companies(props) {
  const client = useApolloClient();
  const [inviteModal, setInviteModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: ownerCompanies,
    });
    setState({
      loading: false,
      data: queryData?.data?.ownerCompanies || [],
    });
    props?.setCurrentCount(queryData?.data?.ownerCompanies?.length || 0);
  };

  const masq = async (user_id, company_id) => {
    const queryData = await client.mutate({
      variables: { user_id: user_id, company_id: company_id },
      mutation: masqueradeUser,
    });
    const token = queryData?.data?.masqueradeUser;
    if (token && props.login) {
      props.login(token);
    }
  };
  const columns = getColumns(props?.user?.company?.id, masq);

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("companies"));
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-companies">
      <div className="admin-search admin-button">
        <Button
          type="primary"
          size="small"
          className="boxed"
          onClick={() => setInviteModal(true)}
        >
          Invite Company
        </Button>
        {inviteModal ? (
          <InviteCompany
            reload={fetchDocuments}
            removeModal={() => setInviteModal(false)}
          />
        ) : null}
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 215px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading companies..."
              : "No companies found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          {/*Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}*/}
        </div>
      )}
    </div>
  );
}

export default Companies;
