import React, { createContext, useContext, useState } from "react";
import { delay } from "_assets/js/helpers";
// Create the context
const BarContext = createContext();

// Create a provider component
export const BarProvider = ({ children }) => {
  const [activeData, setActiveData] = useState(null);
  const [customer, setCustomer] = useState(null);

  const setupCall = async (customer, task, override) => {
    if (override) {
      setActiveData(null);
      await delay(100);
    }
    if (activeData?.mode === "no-call" && !override) {
      closeCall();
    } else if (activeData?.type === "call" && !override) {
      toggleMinimize();
    } else if (customer && !task) {
      setActiveData({
        type: "call",
        mode: "customer-call",
        customer: customer,
        task: null,
        minimize: false,
      });
    } else if (customer && task) {
      setActiveData({
        type: "call",
        mode: "task-call",
        customer: customer,
        task: task,
        minimize: false,
      });
    } else {
      setActiveData({
        type: "call",
        mode: "no-call",
        customer: null,
        task: null,
        minimize: false,
      });
    }
  };

  const setupText = async (customer, task, override) => {
    if (override) {
      setActiveData(null);
      await delay(100);
    }
    if (activeData?.mode === "no-text" && !override) {
      closeCall();
    } else if (activeData?.type === "text" && !override) {
      toggleMinimize();
    } else if (customer && !task) {
      setActiveData({
        type: "text",
        mode: "customer-text",
        customer: customer,
        task: null,
        minimize: false,
      });
    } else if (customer && task) {
      setActiveData({
        type: "text",
        mode: "task-text",
        customer: customer,
        task: task,
        minimize: false,
      });
    } else {
      setActiveData({
        type: "text",
        mode: "no-text",
        customer: null,
        task: null,
        minimize: false,
      });
    }
  };

  const setupEmail = async (customer, task, override) => {
    if (override) {
      setActiveData(null);
      await delay(100);
    }
    if (activeData?.mode === "no-email" && !override) {
      closeCall();
    } else if (activeData?.type === "email" && !override) {
      toggleMinimize();
    } else if (customer && !task) {
      setActiveData({
        type: "email",
        mode: "customer-email",
        customer: customer,
        task: null,
        minimize: false,
      });
    } else if (customer && task) {
      setActiveData({
        type: "email",
        mode: "task-email",
        customer: customer,
        task: task,
        minimize: false,
      });
    } else {
      setActiveData({
        type: "email",
        mode: "no-email",
        customer: null,
        task: null,
        minimize: false,
      });
    }
  };

  const setupMail = async (customer, task, override) => {
    if (override) {
      setActiveData(null);
      await delay(100);
    }
    if (activeData?.mode === "no-mail" && !override) {
      closeCall();
    } else if (activeData?.type === "mail" && !override) {
      toggleMinimize();
    } else if (customer && !task) {
      setActiveData({
        type: "mail",
        mode: "customer-mail",
        customer: customer,
        task: null,
        minimize: false,
      });
    } else if (customer && task) {
      setActiveData({
        type: "mail",
        mode: "task-mail",
        customer: customer,
        task: task,
        minimize: false,
      });
    } else {
      setActiveData({
        type: "mail",
        mode: "no-mail",
        customer: null,
        task: null,
        minimize: false,
      });
    }
  };

  const toggleMinimize = () =>
    setActiveData({ ...activeData, minimize: !activeData.minimize });

  const closeCall = () => setActiveData(null);

  return (
    <BarContext.Provider
      value={{
        setupCall,
        setupText,
        setupEmail,
        setupMail,
        closeCall,
        toggleMinimize,
        activeData,
        customer,
        setCustomer,
      }}
    >
      {children}
    </BarContext.Provider>
  );
};

// Custom hook to use the context
export const useBar = () => {
  return useContext(BarContext);
};
