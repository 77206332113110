import { Text, Tooltip } from "@centrate-io/barn";
import {
  IconSignature,
  IconCoin,
  IconScale,
  IconCalendar,
  IconEdit,
  IconCircleX,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { Tag, TableActions } from "_styleguide";
import { paymentTag } from "_helpers/payments";
import moment from "moment";

export const getColumns = (
  setEditPaymentModal,
  stopPayment,
  userRole,
  partner,
) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Info
        </div>
      ),
      dataIndex: "order",
      key: "order",
      sorter: true,
      width: 240,
      render: (order, data) => (
        <div>
          <Text className="doc-name">
            <IconCoin />
            <b>Payment #{order || 0}</b>
            {data?.is_custom ? <Tag size="small">Custom</Tag> : null}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 130,
      align: "center",
      render: (status) => (
        <Text>
          <Tag type={paymentTag[status]}>{status?.toUpperCase()}</Tag>
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Amount
        </div>
      ),
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (amount) => <Text>{formatMoney(amount)}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          First Attempt
        </div>
      ),
      dataIndex: "first_attempt",
      key: "first_attempt",
      sorter: true,
      render: (first_attempt) => (
        <Text>{moment(parseInt(first_attempt)).utc().format("M/D/YYYY")}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Next Attempt
        </div>
      ),
      dataIndex: "next_attempt",
      key: "next_attempt",
      sorter: true,
      render: (next_attempt) => (
        <Text>{moment(parseInt(next_attempt)).utc().format("M/D/YYYY")}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Last Attempt
        </div>
      ),
      dataIndex: "last_attempt",
      key: "last_attempt",
      sorter: true,
      render: (last_attempt) => (
        <Text>
          {last_attempt ? (
            moment(parseInt(last_attempt)).utc().format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      hidden: userRole === "user" || partner,
      render: (manage, data) => {
        const items = [
          {
            label: (
              <span>
                <IconEdit /> Edit Payment
              </span>
            ),
            onClick: () => setEditPaymentModal(data),
          },
          {
            label: (
              <span className="bad">
                <IconCircleX /> Cancel Payment
              </span>
            ),
            onClick: () => stopPayment(data.id),
          },
        ];
        console.log("partner", partner);
        if (["open", "paused", "failed"].includes(data.status)) {
          return <TableActions items={items} />;
        } else {
          return <TableActions items={[]} disabled />;
        }
      },
    },
  ];
};
