import React from "react";
import { Menu as AntdMenu } from "antd";
import { Link } from "react-router-dom";
import {
  IconPencil,
  IconUsers,
  IconUsersGroup,
  IconBuilding,
  IconPuzzle,
} from "@tabler/icons-react";
import "./Menu.scss";

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});

function Menu(props) {
  const isPartner = props.user?.parent_company ? true : false;

  let items = [
    getItem(
      <Link to="/settings/manage">Manage Company</Link>,
      "manage",
      <IconPencil />,
    ),
    getItem(
      <Link to="/settings/users">User Management</Link>,
      "users",
      <IconUsers />,
    ),
    getItem(
      <Link to="/settings/partners">Partners</Link>,
      "partners",
      <IconBuilding />,
    ),
    getItem(
      <Link to="/settings/teams">Teams</Link>,
      "teams",
      <IconUsersGroup />,
    ),
    getItem(
      <Link to="/settings/integrations">Integrations</Link>,
      "integrations",
      <IconPuzzle />,
    ),
  ];

  if (isPartner) {
    items = items.filter((i) => !["partners"].includes(i.key));
  }

  const menuKeys = [props.tab || "manage"];

  return (
    <div className="settings-menu">
      <div className="am-title">
        <h3>Settings</h3>
      </div>
      <AntdMenu
        theme="light"
        mode="inline"
        selectedKeys={menuKeys}
        items={[...items]}
      />
    </div>
  );
}

export default Menu;
