import React, { useState, useEffect } from "react";
import { Table, Search } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminUsers } from "App/Admin/_graphql/queries/user";
import { getColumns } from "./_data/Columns";
import { Button, message, Modal } from "@centrate-io/barn";
import InviteUser from "./_modals/InviteUser/InviteUser";
import ChangeRole from "./_modals/ChangeRole/ChangeRole";
import { changeUserRole } from "App/Admin/_graphql/mutations/user";
import { getErrors } from "_helpers/api";
import { IconUsers, IconPlus } from "@tabler/icons-react";
import "./Users.scss";

function Users(props) {
  const client = useApolloClient();
  const [inviteModal, setInviteModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: adminUsers,
    });
    setState({
      loading: false,
      data: queryData?.data?.adminUsers || [],
    });
    props?.setCurrentCount(queryData?.data?.adminUsers?.length || 0);
  };

  const removeUser = async (user_id, user_name) => {
    Modal.confirm({
      title: "Are you sure you want remove access for this user?",
      okText: "Remove Access",
      content:
        "This can be reversed at anytime, but this user will lose access to PayPath.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { user_id, role: "removed" },
            mutation: changeUserRole,
          });
          message.success(`Access has been removed for ${user_name}`);
          fetchDocuments();
        } catch (err) {
          message.error(getErrors(err) || "Error removing access, try again");
        }
      },
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "User Management",
      icon: <IconUsers />,
      description: "Manage your current users and seats available",
    });
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (state?.data || []).filter((u) => {
    if ((search?.length || 0) <= 0) return true;
    const nameSearch = u.name?.toLowerCase();
    const idSearch = u.short_id?.toLowerCase();
    return nameSearch.includes(search) || idSearch.includes(search);
  });
  const columns = getColumns(
    props?.user?.id,
    props?.user?.role,
    props?.user?.company?.id,
    setRoleModal,
    removeUser,
  );

  return (
    <div className="settings-users">
      <div className="settings-search">
        <div className="ms-actions">
          <Button
            type="primary"
            size="small"
            onClick={() => setInviteModal(true)}
          >
            <IconPlus />
            Invite User
          </Button>
        </div>
        <div className="ms-filters">
          <Search
            placeholder={`Search ${state.data?.length || ""} users...`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 304 }}
          />
        </div>
      </div>
      {inviteModal ? (
        <InviteUser
          reload={fetchDocuments}
          removeModal={() => setInviteModal(false)}
          user={props.user}
        />
      ) : null}
      {roleModal ? (
        <ChangeRole
          roleUser={roleModal}
          reload={fetchDocuments}
          removeModal={() => setRoleModal(false)}
          user={props.user}
        />
      ) : null}
      <Table
        columns={columns}
        loading={state.loading}
        pagination={state.pagination}
        rowKey={(record) => record.id}
        dataSource={tableData}
        showSorterTooltip={false}
        scroll={{
          y: "calc(80vh - 231px)",
          x: "max-content",
        }}
        locale={{
          emptyText: state.loading ? "Loading users..." : "No users found",
        }}
      />
    </div>
  );
}

export default Users;
