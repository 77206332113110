import React, { useState, useEffect } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import PayPathLogo from "_assets/logos/paypath.svg";
import { logOut } from "_helpers/auth";
import { sendVerification, checkVerification } from "_graphql/mutations/user";
import { useApolloClient } from "@apollo/client";
import { IconArrowRight } from "@tabler/icons-react";
import { switchAccount } from "_graphql/mutations/user";
import moment from "moment";
import "./TwoFactor.scss";

function TwoFactor(props) {
  const client = useApolloClient();
  const [code, setCode] = useState();
  const [channel, setChannel] = useState(
    localStorage.getItem("pp_verify_channel") || "sms",
  );
  const [selectCompany, setSelectCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [intervalId, setIntervalId] = useState(null);
  const { user, login } = props;

  // Submit login and receive token
  const fetchVerification = async (actualChannel) => {
    if (!user.id) return;
    try {
      await client.mutate({
        variables: { user_id: user.id, channel: actualChannel },
        mutation: sendVerification,
      });
      message.success(
        `Verification code sent to: ${actualChannel === "sms" ? user.phone : user.email}`,
      );
    } catch (err) {
      message.error(
        getErrors(err) || "Error sending verification code, try again",
      );
    }
  };

  const otherCompanies = user?.companies || [];

  const switchUser = async (user_id, company_id) => {
    const queryData = await client.mutate({
      variables: { user_id: user_id, company_id: company_id },
      mutation: switchAccount,
    });
    const token = queryData?.data?.switchAccount;
    if (token && login) {
      login(token);
    }
  };

  // Submit login and receive token
  const submitVerification = async () => {
    if (code?.length < 6) return;
    setLoading(true);
    try {
      const submitData = await client.mutate({
        variables: { code: code, channel: channel },
        mutation: checkVerification,
      });
      if (submitData?.data?.checkVerification) {
        setLoading(false);
        if (otherCompanies.length <= 1) {
          login(submitData?.data?.checkVerification);
          message.success(`Verification successful, you are now logged in`);
        } else {
          setSelectCompany(true);
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      setLoading(false);
      message.error(getErrors(err) || "Incorrect code, try again");
    }
  };

  /* Google Login */
  useEffect(() => {
    fetchVerification(channel);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    setIntervalId(id);
    return () => clearInterval(id);
    // eslint-disable-next-line
  }, []);

  const resendVerification = async () => {
    if (seconds > 0) return;
    await fetchVerification(channel);
    clearInterval(intervalId);
    setSeconds(30);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    setIntervalId(id);
  };

  const switchChannel = async () => {
    const newChannel = channel === "sms" ? "email" : "sms";
    localStorage.setItem("pp_verify_channel", newChannel);
    setChannel(newChannel);
    setCode(undefined);
    await fetchVerification(newChannel);
  };

  const channelType = {
    sms: "text message",
    email: "email",
  };

  return (
    <React.Fragment>
      <div className="auth-two-factor">
        <div className="auth-info">
          <img className="login-logo" src={PayPathLogo} alt="PayPathAI" />
        </div>
        {selectCompany ? (
          <div className="login-form">
            {otherCompanies.map((c, i) => (
              <div key={`switch-${c.id}`} className="company-select">
                <div onClick={() => switchUser(user.id, c.id)}>
                  <h5>{c.name}</h5>
                  <p>
                    <i>{c.role}</i> - Joined{" "}
                    {moment(c.joined_date).format("M/D/YYYY")}
                  </p>
                </div>
                {i !== otherCompanies?.length - 1 ? (
                  <div className="divider" />
                ) : null}
              </div>
            ))}
            <div className="notice">Select an account to login to</div>
          </div>
        ) : (
          <div className="login-form">
            <div className="line">
              <Input.Label>
                Enter {channelType[channel]} code received:
              </Input.Label>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                size="large"
              />
            </div>

            <div className="forgot">
              {seconds > 0 ? (
                <div className="pp-link disabled">
                  Resend code in {seconds} second(s)
                </div>
              ) : (
                <div className="pp-link" onClick={resendVerification}>
                  Resend verification code
                </div>
              )}
              <div className="channel">
                <div className="pp-link" onClick={switchChannel}>
                  Use{" "}
                  {channel === "sms"
                    ? channelType["email"]
                    : channelType["sms"]}{" "}
                  instead
                  <IconArrowRight />
                </div>
              </div>
            </div>

            <Button
              loading={loading}
              disabled={code?.length < 6}
              type="secondary"
              onClick={submitVerification}
              size="large"
              block
            >
              Submit code
            </Button>
          </div>
        )}
      </div>
      <Button className="signout" type="secondary" onClick={logOut}>
        Sign out
      </Button>
    </React.Fragment>
  );
}

export default TwoFactor;
