import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import {
  IconSignature,
  // IconMail,
  IconAddressBook,
  IconCalendar,
  IconMap2,
  IconFile,
  // IconIdBadge2,
  IconUser,
} from "@tabler/icons-react";
import { prettyPhone } from "_assets/js/helpers";
import { Button } from "@centrate-io/barn";
import moment from "moment";

export const getColumns = (setIsModifying) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Lead
        </div>
      ),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 280,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconFile />
            <b>
              {data.first_name} {data.last_name}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data.short_id}</i>
          </Text>
        </div>
      ),
    },

    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Contact
        </div>
      ),
      dataIndex: "phone",
      key: "phone",
      width: 220,
      sorter: true,
      render: (phone, data) => (
        <div>
          <Text>{data?.email || <i>n/a</i>}</Text>
          <Text>{prettyPhone(phone)}</Text>
        </div>
      ),
    },
    // {
    //   title: (
    //     <div className="column-name">
    //       <IconIdBadge2 />
    //       SSN
    //     </div>
    //   ),
    //   dataIndex: "ssn",
    //   key: "ssn",
    //   width: 120,
    //   sorter: true,
    //   render: (ssn) => (ssn ? <Text>{ssn}</Text> : <i>n/a</i>),
    // },
    // {
    //   title: (
    //     <div className="column-name">
    //       <IconCalendarUser />
    //       DOB
    //     </div>
    //   ),
    //   dataIndex: "dob",
    //   key: "dob",
    //   width: 120,
    //   sorter: true,
    //   render: (dob) =>
    //     dob ? (
    //       <Text>{moment(parseInt(dob)).format("M/D/YYYY")}</Text>
    //     ) : (
    //       <i>n/a</i>
    //     ),
    // },
    {
      title: (
        <div className="column-name">
          <IconMap2 />
          Address
        </div>
      ),
      dataIndex: "address",
      key: "address",
      sorter: true,
      width: 300,
      render: (address, data) =>
        data.address ? (
          <div>
            <Text>
              {data.address} {data.address_line_two}
            </Text>
            <Text>
              {data.city}, {data.state} {data.zip}
            </Text>
          </div>
        ) : (
          <i>n/a</i>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Created
        </div>
      ),
      dataIndex: "audit.account_date",
      key: "audit.account_date",
      width: 150,
      sorter: true,
      render: (audit, data) =>
        data?.audit?.account_date ? (
          <Text>
            {moment(parseInt(data?.audit?.account_date)).format(
              "M/D/YY @ h:mm a",
            )}
          </Text>
        ) : (
          <i>n/a</i>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Created By
        </div>
      ),
      dataIndex: "user",
      key: "user",
      width: 280,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{data?.user?.name}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.company?.name}</i>
          </Text>
        </div>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 100,
      align: "center",
      fixed: "right",
      render: (address, data) => (
        <div className="table-actions">
          <Link
            to={`/customer/${data.short_id}/tasks`}
            onClick={() => setIsModifying(true)}
          >
            <Button type="secondary" size="small">
              Manage
            </Button>
          </Link>
          <Link to={`/wizard/${data.id}`}>
            <Button type="primary" size="small">
              Edit Application
            </Button>
          </Link>
        </div>
      ),
    },
  ];
};
