import { gql } from "@apollo/client";

export const ownerCompanies = gql`
  query {
    ownerCompanies {
      id
      short_id
      name
      user_id
      companies
      user_count
      role

      parent {
        name
        role
        id
      }

      user {
        id
        short_id
        name
        email
      }

      invited_date
      joined_date
      createdAt
    }
  }
`;
