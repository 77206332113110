import React, { useState } from "react";
import Illustration from "_assets/images/no_workflows.png";
import { IconSubtask, IconPlus } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import ApplyWorkflow from "./_components/ApplyWorkflow/ApplyWorkflow";
import "./NoTasks.scss";

function NoTasks(props) {
  const [showModal, setShowModal] = useState(false);

  const { status } = props;

  let info = {
    title: "No Tasks Yet",
    desc: "Apply a workflow to this customer to start with a pre-defined set of tasks to get started, or you can add tasks manually",
    actions: true,
  };

  if (status === "cancelled") {
    info = {
      title: "Customer Cancelled",
      desc: "This customer has been cancelled and tasks are unavailable until the account is active again.",
      actions: false,
    };
  }

  return (
    <div className="no-tasks-pls">
      {showModal ? (
        <ApplyWorkflow
          applicationId={props.applicationId}
          reload={props.reload}
          removeModal={() => setShowModal(false)}
        />
      ) : null}
      <img src={Illustration} alt="PayPath" />
      <h2>{info.title}</h2>
      <p>{info.desc}</p>
      {info.actions ? (
        <div className="nt-actions">
          <Button type="secondary" onClick={() => props.showCustomTask(true)}>
            <IconPlus /> Add Task
          </Button>
          <Button type="primary" onClick={() => setShowModal(true)}>
            <IconSubtask /> Apply Workflow
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default NoTasks;
