import React, { useState, useEffect } from "react";
import {
  IconClipboardText,
  IconCreditCard,
  IconReport,
  IconFileTypePdf,
  IconBuildingBank,
  IconCoin,
  IconNote,
  IconActivity,
  IconSubtask,
} from "@tabler/icons-react";
import { Tabs } from "_styleguide";
import Information from "../Information/Information";
import Tradelines from "../Tradelines/Tradelines";
import CreditReports from "../CreditReports/CreditReports";
import BankAccounts from "../BankAccounts/BankAccounts";
import Payments from "../Payments/Payments";
import Notes from "../Notes/Notes";
import Activity from "../Activity/Activity";
import Documents from "../Documents/Documents";
import Tasks from "../Tasks/Tasks";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@centrate-io/barn";
import "./Navigation.scss";

function Navigation(props) {
  let navigate = useNavigate();
  const [tab, setTab] = useState(props?.default || "tasks");
  const { status } = props;
  const abstractProps = {
    applicationId: props.applicationId,
    shortId: props.shortId,
    setTab: setTab,
    user: props.user,
    app: props.appData,
    status: props.status,
    reloadApplication: props.reloadApplication,
  };
  const isDisabled = status === "application";

  useEffect(() => {
    if (tab !== props.default) {
      setTab(props.default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.default]);

  let items = [
    {
      key: "tasks",
      label: (
        <div className="tab-name">
          <IconSubtask />
          Tasks
        </div>
      ),
      children: <Tasks {...abstractProps} />,
    },
    {
      key: "information",
      label: (
        <div className="tab-name">
          <IconClipboardText />
          Information
        </div>
      ),
      children: <Information {...abstractProps} />,
    },
    {
      key: "tradelines",
      label: (
        <div className="tab-name">
          <IconCreditCard />
          Credit Lines
        </div>
      ),
      children: <Tradelines {...abstractProps} />,
    },
    // {
    //   key: "credit-reports",
    //   label: (
    //     <div className="tab-name">
    //       <IconReport />
    //       Credit Reports
    //     </div>
    //   ),
    //   children: <CreditReports {...abstractProps} />,
    // },
    {
      key: "payments",
      label: (
        <div className="tab-name">
          <IconCoin />
          Payments
        </div>
      ),
      children: <Payments {...abstractProps} />,
    },
    {
      key: "documents",
      label: (
        <div className="tab-name">
          <IconFileTypePdf />
          Documents
        </div>
      ),
      children: <Documents {...abstractProps} />,
    },
    {
      key: "bank-accounts",
      label: (
        <div className="tab-name">
          <IconBuildingBank />
          Bank Accounts
        </div>
      ),
      children: <BankAccounts {...abstractProps} />,
    },
    {
      key: "notes",
      label: (
        <div className="tab-name">
          <IconNote />
          Notes
        </div>
      ),
      children: <Notes {...abstractProps} />,
    },
    {
      key: "activity",
      label: (
        <div className="tab-name">
          <IconActivity />
          Activity
        </div>
      ),
      children: <Activity {...abstractProps} />,
    },
  ];

  if (isDisabled) {
    items = [
      {
        key: "tasks",
        label: (
          <div className="tab-name">
            <IconSubtask />
            Tasks
          </div>
        ),
        children: <Tasks {...abstractProps} />,
      },
      {
        key: "documents",
        label: (
          <div className="tab-name">
            <IconFileTypePdf />
            Documents
          </div>
        ),
        children: <Documents {...abstractProps} />,
      },
      {
        key: "notes",
        label: (
          <div className="tab-name">
            <IconNote />
            Notes
          </div>
        ),
        children: <Notes {...abstractProps} />,
      },
      {
        key: "activity",
        label: (
          <div className="tab-name">
            <IconActivity />
            Activity
          </div>
        ),
        children: <Activity {...abstractProps} />,
      },
    ];
  }

  return (
    <div className="admin-tabs">
      <Tabs
        onChange={(key) => {
          navigate(`/customer/${props.shortId}/${key}`);
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab || "tasks"}
      />
      {isDisabled ? (
        <div
          className={`admin-search admin-button ${props.wasModified ? "modified" : ""}`}
        >
          <Link to={`/wizard/${props.applicationId}`}>
            <Button type="primary" size="small">
              Edit Application
            </Button>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default Navigation;
