import { gql } from "@apollo/client";

export const saveApplication = gql`
  mutation saveApplication($application: ApplicationInput!) {
    saveApplication(application: $application)
  }
`;

export const createSubscription = gql`
  mutation createSubscription($application_id: String!, $pdf_content: String!) {
    createSubscription(
      application_id: $application_id
      pdf_content: $pdf_content
    )
  }
`;

export const exchangeToken = gql`
  mutation exchangeToken(
    $application_id: String!
    $account_id: String!
    $public_token: String!
  ) {
    exchangeToken(
      application_id: $application_id
      account_id: $account_id
      public_token: $public_token
    )
  }
`;

export const userVerifySSN = gql`
  mutation userVerifySSN($application_id: String!, $ssn: String!) {
    userVerifySSN(application_id: $application_id, ssn: $ssn)
  }
`;

export const cancelSubscription = gql`
  mutation cancelSubscription($application_id: String!, $reason: String) {
    cancelSubscription(application_id: $application_id, reason: $reason)
  }
`;

export const pauseSubscription = gql`
  mutation pauseSubscription($application_id: String!, $reason: String) {
    pauseSubscription(application_id: $application_id, reason: $reason)
  }
`;

export const reenrollSubscription = gql`
  mutation reenrollSubscription($application_id: String!, $reason: String) {
    reenrollSubscription(application_id: $application_id, reason: $reason)
  }
`;

export const unpauseSubscription = gql`
  mutation unpauseSubscription($application_id: String!) {
    unpauseSubscription(application_id: $application_id)
  }
`;
