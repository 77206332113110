import React from "react";
import Illustration from "_assets/images/no_workflows.png";
import { IconPlus } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./Empty.scss";

function Empty(props) {
  return (
    <div className="pp-empty">
      <img src={Illustration} alt="PayPath" />
      {props.title && <h2>{props.title}</h2>}
      {props.description && <p>{props.description}</p>}
      {props.action ? (
        <Button type="primary" onClick={props.onClick}>
          {props.actionIcon ? <IconPlus /> : null}
          {props.action ? props.action : ""}
        </Button>
      ) : null}
    </div>
  );
}

export default Empty;
